<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col></ion-col>
          <ion-col></ion-col>
          <ion-col>  
            <ion-button  color="dark" style="width: 45px;" @click="openModalAdd">
              +
            </ion-button>
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
            
              <table class="table table-responsive-sm table-striped table-align-middle">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Correo Electrónico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente in clientes.data" :key="cliente">
                    <td>{{cliente?.name}}</td>
                    <td>{{cliente?.email}}</td>
                    <td>
                      <ion-button color="primary" @click="getSiniestrosByUser(cliente)">
                        Ver siniestros
                      </ion-button>
                    </td>
                    <td>
                      <ion-button color="primary"  @click="openModalEdit(cliente)">
                        Editar
                      </ion-button>
                      <ion-button color="primary"  @click="eliminarUserConfirmacion(cliente)">
                        Eliminar
                      </ion-button>
                    </td>
                  </tr>
                 
                </tbody>
              </table>
              <nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                  <li  :class="{'page-item' : true , 'disabled' : !clientes.prev_page_url }">
                    <a class="page-link"   @click="page(clientes.prev_page_url)" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item"><a class="page-link">{{clientes.current_page}}</a></li>
                  <li :class="{'page-item' : true , 'disabled' : !clientes.next_page_url }">
                    <a class="page-link"  @click="page(clientes.next_page_url)">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
        <div class="card" v-if="has_siniestro">
          <ion-title size="large">
            Siniestros
          </ion-title>
          <table class="table table-responsive-sm table-striped table-align-middle">
            <thead>
              <tr>
                <th>Clientes</th>
                <th>Fecha de registro</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="siniestro in siniestros.data" :key="siniestro"  :class="{'selected' : siniestro.id == siniesto_selected}">
                <td>
                  <template v-for= "(cliente,key) in siniestro.clientes" :key="cliente">
                    {{cliente.name}}<template v-if="siniestro.clientes.length != (key+1)"><br></template>
                  </template>
                </td>
                <td style="font-size: 12px">
                      {{new Date(siniestro?.created_at).toLocaleDateString()}} 
                      <br> 
                      {{new Date(siniestro?.created_at).toLocaleTimeString() }}
                    </td>
              </tr>
               <tr v-if="siniestros.data.length == 0" >
                <td colspan="2">Actualmente el cliente no tiene siniestro</td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="page navigation example">
            <ul class="pagination justify-content-center">
              <li  :class="{'page-item' : true , 'disabled' : !siniestros.prev_page_url }">
                <a class="page-link"   @click="page(siniestros.prev_page_url)" tabindex="-1">Anterior</a>
              </li>
              <li class="page-item"><a class="page-link">{{siniestros.current_page}}</a></li>
              <li :class="{'page-item' : true , 'disabled' : !siniestros.next_page_url }">
                <a class="page-link"  @click="page(siniestros.next_page_url)">Siguiente</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation,alertController } from '@ionic/vue';
import toast from '@/plugins/toast'
import axios from 'axios'
import agregarCliente from './agregarCliente';
import editarCliente from './editarCliente';
import { mapGetters } from "vuex";

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      clientes : [],
      has_siniestro : false,
      siniestros : []
    }
  },
  mounted(){
    this.getUser.role.name != 'Administrador' ? this.getClientesByUser() : this.getClientes();
  },
  methods:{
    getClientes(){
       axios.get('/api/users?role=Cliente')
      .then(res => {
        console.log(res.data.data)
        this.clientes = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getClientesByUser(){
       axios.get('/api/users/cliente/byUser')
      .then(res => {
        console.log(res.data.data)
        this.clientes = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestrosByUser(cliente){

      axios.get('/api/siniestros/by/cliente/'+cliente.id)
      .then(res => {
        console.log(res.data.data)

        this.siniestros = res.data.data
         this.has_siniestro = true
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.clientes = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
     async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarCliente,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
        })


   
      modal.present();

      modal.onDidDismiss().then((data) => {
         this.getUser.role.name != 'Administrador' ? this.getClientesByUser() : this.getClientes();
        console.log(data)
      })

    },
    async openModalEdit(cliente) {
    
      const modal = await modalController
        .create({
          component: editarCliente,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {cliente : cliente}
        })


   
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getUser.role.name != 'Administrador' ? this.getClientesByUser() : this.getClientes();
      })

    },
    async eliminarUserConfirmacion(user) {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar este Cliente?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()

                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/users/'+user.id)
                .then(data =>{
                  loading.dismiss()
                  this.getUser.role.name != 'Administrador' ? this.getClientesByUser() : this.getClientes();
                  toast.openToast("Cliente eliminado exitosamente","error",2000)
                  console.log(data)

                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

</style>
